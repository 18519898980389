.app {
  /* background-color: red; */
  display:flex;
  flex-direction: column;
}

.logo-container {
  justify-content: center;
  display: flex;
}

.menu-container {
  display: flex;
  justify-content: center;
}

.logo {
  height: 500px;
}



.menu-container > a {
  font-size: x-large;
  margin-right: 10px;
  background-color: green;
  border-radius: 8px;
  color: white;
  padding: 10px;
  text-decoration: none;
  margin-bottom: 10px;
}

.active {
  background-color: lightgreen !important;
  color: black !important;
}

.outlet-container {
  display: flex;
  justify-content: center;
}

.menu-container {
  display: flex;
  flex-direction: row;
}

.outlet {
  width: 1000px;
  /* background-color: lavenderblush;
  border-radius: 8px; */
  padding: 15px;
  font-family: 'Public Sans', sans-serif;
  line-height: 1.4;
}

.outlet-content {
  font-size: x-large;
  /* font-size: xx-large;
  font-family: otjefont; */
}

.photo-style-1 {
  height: 400px;
  margin-right: 1px;
}

.text-horizontal {
  display: flex;
}

.text-block {
  text-align: justify;
}

.photo-h {
  justify-content: center;
  display: flex;
}

.photo-style-m {
  height: 400px;
  margin-right: 15px;
  margin-top: 10px;
}

.table {
  margin-left: 50px;
}

.tableColumnHour {
  width: 200px;
}

@media only screen and (max-width: 620px) {
  /* For mobile phones: */

  .logo {
    width: 100%;
    height: 200px;
  }

  .outlet {
    width: 100%;
  }

  .menu-container {
    flex-direction: column;
  }

  .text-horizontal {
    flex-direction: column;
  }

  .photo-style-m {
    margin-top: 0;
    height: auto;
  }

  .photo-h {
    flex-direction: column;
  }

  .photo-style-1 {
    height: auto;
    margin-bottom: 1px;
  }

  .outlet-content {
    font-size: large;
  }

  .table {
    margin-left: auto;
  }

  .table > tr > td {
    border-bottom: 1px solid darkgrey;
  }
  
}